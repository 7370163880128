import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Select,
  Button,
  Modal,
  Spin,
  Table,
  Input,
  Popover,
  Pagination,
  Popconfirm,
  Tooltip,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import parse from "html-react-parser";
import moment from "moment";
import "react-image-gallery/styles/css/image-gallery.css";
import Info_icon from "../../../assets/images/info_icon.png";
import drophint from "../../../assets/images/choose-setting/drophint.png";
import printicon from "../../../assets/images/icons/printicon.svg";
import calender from "../../../assets/images/choose-setting/calender.svg";
import emailicon from "../../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../../assets/images/choose-setting/infoblack.svg";
import GIA from "../../../assets/images/choose-setting/gia.jpg";
import IGI from "../../../assets/images/choose-setting/igi.jpg";
import AGS from "../../../assets/images/choose-setting/ags.jpg";
import EGL from "../../../assets/images/choose-setting/egl.jpg";
import IMG5 from "../../../assets/images/choose-setting/img5.jpg";
import facebookLive from "../../../assets/images/facebook-like.jpg";
import DtlFacebook from "../../../assets/images/fb__icon.svg";
import DtlTwitter from "../../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../../assets/images/instragm__icon.svg";
import {
  handleComaparePageIds,
  handleViewDetailsPage,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import { NotificationManager } from "react-notifications";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import { handleActiveTab } from "../../../actions/header/headerActions";
import ChooseViewDetailsServices from "../../../services/choose-view-details.service";
import { handleHideEditChooseThisMounting } from "../../../actions/chooseYourEarning/chooseYourEarningActions";
import colorPanelService from "../../../services/color-panel.service";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { setGlobalFontFamily } from "../../../actions/PreviewColor/previewColor";
import { useTranslation } from "react-i18next";

const initialCertInfoInputData = {
  name: "",
  email: "",
  phoneNumber: "",
  comments: "",
};

const initialCertInfoInputDataVal = {
  nameVal: "",
  emailVal: "",
  phoneNumberVal: "",
  commentsVal: "",
};

const initialDropHintInputData = {
  name: "",
  email: "",
  recipientName: "",
  recipientEmail: "",
  giftReason: "",
  giftDeadline: "",
  personalMessage: "",
};

const initialDropHintInputDataVal = {
  nameval: "",
  emailval: "",
  recipientNameval: "",
  recipientEmailval: "",
  giftReasonval: "",
  giftDeadlineval: "",
  personalMessageval: "",
};

const initialRequestMoreInfoData = {
  name: "",
  email: "",
  phoneNumber: "",
  contactPreference: "byEmail",
  personalMessage: "",
};

const initialRequestMoreInfoDataVal = {
  nameval: "",
  emailval: "",
  phoneNumberval: "",
  contactPreferenceval: "",
  personalMessageval: "",
};

const initialEmailFriendInputData = {
  name: "",
  email: "",
  friendName: "",
  friendEmail: "",
  personalMessage: "",
};

const initialEmailFriendInputDataVal = {
  nameval: "",
  emailval: "",
  friendNameval: "",
  friendEmailval: "",
  personalMessageval: "",
};

const initialScheduleViewingInputData = {
  name: "",
  email: "",
  phoneNumber: "",
  location: "",
  appointmentDate: "",
  appointmentTime: "",
  address: "",
  message: "",
};

const initialScheduleViewingInputDataVal = {
  nameval: "",
  emailVal: "",
  phoneNumberVal: "",
  locationVal: "",
  appointmentDateVal: "",
  appointmentTimeVal: "",
  addressVal: "",
  messageVal: "",
};

export const ViewDiamondDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useLocation().search;
  const { Option } = Select;
  const { Search } = Input;
  const Details = useSelector(
    (state) => state.selectYourDiamondReducer.selectedRow
  );
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const currentTab = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const [diamondDetails, setDiamondDetails] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [completeData, setCompleteData] = useState({});
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [viewStateLoading, setViewStateLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  const DealerIDForSelectedDiamond = localStorage.getItem(
    "DealerIDForSelectedDiamond"
  );
  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });
  const [internalUseDataval, setInternalUseDataval] = useState({
    passwordval: "",
  });
  const [displayImage, setDisplayImage] = useState("");
  const [internalButtonSave, setInternalButtonSave] = useState(false);
  const [pinterestIcons, setPinterestIcons] = useState(false);
  const [twitterIcons, setTwitterIcons] = useState(false);
  const [facebookIcons, setFacebookIcons] = useState(false);
  const [instaGramIcons, setInstaGramIcons] = useState(false);
  const [facebookLikeIcons, setFacebookLikeIcons] = useState(false);
  const [scheduleHide, setScheduleHide] = useState(true);
  const [internalUseLinkHide, setInternalUseLinkHide] = useState(false);
  const [adressHide, setAdressHide] = useState(true);
  const [callForPrice, setCallForPrice] = useState(false);
  const [showDealerInfo, setShowDealerInfo] = useState();
  const [dealerInfo, setDealerInfo] = useState(false);
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
  const [internalUseModal, setInternalUseModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [pageName, setPageName] = useState("");
  const [rowDetails, setRowDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [getTimedate, setGetTimeDate] = useState([]);
  const [locationDetails, setLocationDetails] = useState([]);
  const [loadingDiamondGrid, setLoadingDiamondGrid] = useState(false);

  const [locationData, setLocationData] = useState({
    locationName: "",
    phone: "",
    emailID: "",
  });
  const [mountDetails, setMountDetails] = useState({
    image: "",
    sizeimage: "",
    facbookUrl: "",
    facebookLikeUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    pinterestUrl: "",
  });

  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [viewCertModal, setviewCertModal] = useState(false);
  const [requestMoreModal, setRequestMoreModal] = useState(false);
  const [showDropModal, setShowDropModal] = useState(false);
  const [scheduleViewing, setOpenScheduleViewing] = useState(false);
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [certInfoData, setcertInfoData] = useState(initialCertInfoInputData);
  const [certInfoValidation, setcertInfoDataValidation] = useState(
    initialCertInfoInputDataVal
  );
  const [dropHintInputData, setDropHintInputData] = useState(
    initialDropHintInputData
  );
  const [dropHintInputDataValidation, setDropHintInputDataValidation] =
    useState(initialDropHintInputDataVal);
  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState(
    initialRequestMoreInfoData
  );
  const [
    requestMoreInfoInputDataValidation,
    setRequestMoreInfoInputDataValidation,
  ] = useState(initialRequestMoreInfoDataVal);

  const [emailFriendInputData, setEmailFriendInputData] = useState(
    initialEmailFriendInputData
  );

  const [emailFriendInputDataValidation, setEmailFriendInputDataValidation] =
    useState(initialEmailFriendInputDataVal);

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState(
    initialScheduleViewingInputData
  );

  const [
    scheduleViewingInputDataValidation,
    setScheduleViewingInputDataValidation,
  ] = useState(initialScheduleViewingInputDataVal);
  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [similarToogle, setSimilarToogle] = useState(true);
  const [confirmScheduleModal, setConfirmScheduleModal] = useState(false);

  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const diamondId = new URLSearchParams(search).get("diamondId");
  const socialpageNo = new URLSearchParams(search).get("pageNo");
  const socialpageName = new URLSearchParams(search).get("pageName");
  const socialshapeFilters = new URLSearchParams(search).get("shapeFilters");
  const socialtxtCutlet = new URLSearchParams(search).get("txtCutlet");
  const socialtxtClarity = new URLSearchParams(search).get("txtClarity");
  const sociaColorPriority = new URLSearchParams(search).get(
    "intColorPriority"
  );
  const isLab = new URLSearchParams(search).get("isLab");

  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;

              dispatch(setGlobalFontFamily(newColorObj.chooseFont));

              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;
  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  const handleSetRowDetils = (e, record) => {
    setRowDetails(record);
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    handleSimilarDiamondList(
      dealerId,
      completeData,
      pageNo,
      pageName,
      "",
      pageSize,
      column,
      type
    );
  };

  const columns = [
    {
      title: t("Shape"),
      dataIndex: "txtShape",
      width: 180,
      // sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div className="shape__img">
            <img src={row.ImageFileName} alt="" />
            <span className="cut1-span">{row.txtShape}</span>
          </div>
        );
      },
    },
    {
      title: t("Carat"),
      dataIndex: "fltCarat",
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Color"),
      dataIndex: "txtColor",
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Clarity"),
      dataIndex: "txtClarity",
      width: 120,
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Cut"),
      dataIndex: "txtCutGrade",
      //responsive: ["lg"],
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Depth"),
      responsive: ["lg"],
      dataIndex: "fltDepth",
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Table"),
      dataIndex: "fltTable",
      responsive: ["lg"],
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Polish"),
      dataIndex: "txtPolish",
      responsive: ["lg"],
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Sym."),
      dataIndex: "txtSymmetry",
      responsive: ["lg"],
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Measure."),
      dataIndex: "txtMeasurements",
      width: 150,
      responsive: ["lg"],
      // sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Cert."),
      dataIndex: "txtCertificate",
      ellipsis: true,
      // sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {row.txtCertificate == "None" ? (
              <span
                onClick={() => handleOpenCertificateTab(row)}
                className="linkText"
              >
                {row.txtCertificate}
              </span>
            ) : (
              <span
                onClick={() => handleOpenCertificate(row.CERTLINK)}
                className="linkText"
              >
                {row.txtCertificate}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <>
          {t("Price")} ({diamondInfoDetails?.currencycode ?? ""}
          {currencySymbol ?? ""})
        </>
      ),
      dataIndex: "RealPrice",
      width: 150,
      // sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {callForPrice ? (
              <span>{("Call For Price")}</span>
            ) : (
              <span>
                {row.RealPrice == "0" ? (
                  <>
                    Call <span className="hideInMobile">For Price</span>
                  </>
                ) : (
                  <span>
                    {row?.currencycode ?? ""} {row.CurrencySign}
                    {parseInt(row.RealPrice)
                      .toFixed()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                )}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: t("Action"),
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>

            <Popover
              content={() => content(row)}
              trigger="hover"
              placement="left"
            >
              <div className="image__block hideInMobile">
                <img
                  // onMouseOver={() => handleOnClick(row)}
                  src={Info_icon}
                  alt=""
                />
              </div>
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  const content = (row) => (
    <div className="info__tooltip">
      <p>
        <span>{t("Depth")}</span>
        <span>:</span>
        <span>{row?.fltDepth}</span>
      </p>
      <p>
        <span>{t("Table")}</span>
        <span>:</span>
        <span>{row?.fltTable}</span>
      </p>
      <p>
        <span>{t("Polish")}</span>
        <span>:</span>
        <span>{row?.txtPolish}</span>
      </p>
      <p>
        <span>{t("Symmetry")}</span>
        <span>:</span>
        <span>{row?.txtSymmetry}</span>
      </p>
      <p>
        <span>{t("Measurement")}</span>
        <span>:</span>
        <span>{row?.txtMeasurements}</span>
      </p>
    </div>
  );

  useEffect(() => {
    if (isSocialPage == "True") {
      const diamondDetails = {
        shapeFilters: socialshapeFilters,
        txtCutlet: socialtxtCutlet,
        txtClarity: socialtxtClarity,
        intColorPriority: sociaColorPriority,
        pageName: socialpageName,
        txtCutGrade: socialtxtCutlet,
      };
      setDealerId(paramdealerId);
      setDiamondDetails(diamondId);
      setCompleteData(Details);
      handleGetDiamondDetails(paramdealerId, diamondId, socialpageName);
      handleGetSocialDetails(paramdealerId);
      handleGetSimilarDiamond(paramdealerId, diamondDetails, socialpageNo);
      GetLocationLoadDetails(paramdealerId);
      handleGetDateandTime(paramdealerId, "", "", "");
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          setDealerId(loginDetails.dealerId);
          if (Details) {
            if (Details.intDiamondId) {
              setDiamondDetails(Details.intDiamondId);
              setCompleteData(Details);
              if (Details.pageName) {
                handleGetDiamondDetails(
                  loginDetails.dealerId,
                  Details.intDiamondId
                );
                handleGetSocialDetails(loginDetails.dealerId);
                handleGetDateandTime(dealerId, "", "", "");
              }
            }
          }
          handleGetSimilarDiamond(loginDetails.dealerId, Details, pageNo);
          GetLocationLoadDetails(loginDetails.dealerId);
        }
      }
    }
  }, [similarToogle]);

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  const GetLocationLoadDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      SelectYourDiamondService.GetLocationDetails(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            setLocationDetails(responseData.Table);

            setLoading(false);
          } else {
            setLocationDetails([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDiamondDetails = (id, diamondId, socialpageName) => {
    try {
      let inputData = {
        did: diamondId,
        dealerID: id,
        countryTaxRate: "77",
        type: socialpageName,
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetRingBuilderViewDetailsMineLabDiamond(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let newRe = response.data.responseData;

          if (message == "Success") {
            setDiamondInfoDetails(responseData);
            setViewStateLoading(false);
            getIpData(responseData, id);

            if (Object.keys(newRe).length > 0) {
              let arr = [];
              let obj = {};
              obj["original"] = responseData.pendantImgPath;
              obj["thumbnail"] = responseData.pendantImgPath;
              arr.push(obj);
              setMountDetails((prevState) => ({
                ...prevState,
                image: responseData.pendantImgPath,
                sizeimage: responseData.htmlLink,
                facbookUrl: responseData.faceBookLink,
                facebookLikeUrl: responseData.faceBookLike,
                twitterUrl: responseData.twitterLink,
                instagramUrl: responseData.instaLink,
                pinterestUrl: responseData.pinterestLink,
              }));
              setCurrencySymbol(responseData.currencySign);
              handleGetDealerLocationData(id, Details.dealerID);
            } else {
              setViewStateLoading(false);
            }
          } else {
            setViewStateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setViewStateLoading(false);
        });
    } catch (error) {
      console.log(error);
      setViewStateLoading(false);
    }
  };
  const getIpData = async (responseData, id) => {
    const diamondData = responseData;
    const res = await axios.get("https://ipinfo.io/json");
    handleProductTracking(diamondData, res.data.IPv4, id);
  };

  const handleProductTracking = async (res, ip, id) => {
    try {
      let inputData = {
        dateLinked: "",
        dealerID: id ? id.toString() : "",
        diamondInventoryID: res.intDiamondId ? res.intDiamondId : 0,
        completeSearchURL: window.location.href,
        diamondDealerID: res.manuID ? res.manuID : 0,
        hitCounter: 0,
        usersIPAddress: "",
        dealerStockNo: res.skuNo.toString(),
        isFromRingBuilder: false,
        cut: res.cut ? res.cut.toString() : "",
        carat: res.carat ? res.carat.toString() : "",
        color: res.color ? res.color.toString() : "",
        clarity: res.clarity ? res.clarity.toString() : "",
        fltPrice: res.fltPrice ? res.fltPrice.toString() : "",
        girdle: "",
        culet: res.culet ? res.culet.toString() : "",
        polish: res.polish ? res.polish.toString() : "",
        symmetry: res.symmetry ? res.symmetry.toString() : "",
        comments: "",
        fluorescence: res.fluorescence ? res.fluorescence.toString() : "",
        measurements: res.measurements ? res.measurements.toString() : "",
        certificate: res.certificate ? res.certificate.toString() : "",
        certificateNo: res.certificateNo ? res.certificateNo.toString() : "",
        depth: res.depth ? res.depth.toString() : "",
        tableMes: res.tableMes ? res.tableMes.toString() : "",
        cutGrade: res.cutGrade ? res.cutGrade.toString() : "",
        sellingPrice: res.price ? res.price.toString() : "",
        isLabgrown: socialpageName == "Lab" ? "1" : "0",
      };
      await SelectYourDiamondService.DiamondTracking(inputData)
        .then((response) => {})
        .catch((error) => {});
    } catch (error) {}
  };

  const handleGetSocialDetails = (id) => {
    try {
      let inputData = {
        dealerID: parseInt(id),
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetsPendantBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData.length > 0) {
              setPinterestIcons(responseData[0].showPinterestShare);
              setTwitterIcons(responseData[0].showTwitterShare);
              setFacebookIcons(responseData[0].showFacebookShare);
              setScheduleHide(responseData[0].showScheduleView);
              setAdressHide(responseData[0].showAddresses);
              setInstaGramIcons(responseData[0].showInstagramShare);
              setFacebookLikeIcons(responseData[0].showFacebookLike);
              setInternalUseLinkHide(responseData[0].internalLinkPB);
              setCallForPrice(responseData[0].callForPricependant);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenCertificateTab = (row) => {
    setRequestMoreCertModal(true);
    setDiamondInfoDetails(row);
  };

  const showCertInfoModal = () => {
    handleScrollToTop();
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setRequestMoreInfoInputData(initialRequestMoreInfoData);
    setcertInfoDataValidation(initialCertInfoInputDataVal);
  };

  const showCertModal = () => {
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setviewCertModal(false);
  };

  const closeEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData(initialEmailFriendInputData);
    setEmailFriendInputDataValidation(initialEmailFriendInputDataVal);
  };

  const closeScheduleViewingModal = () => {
    setOpenScheduleViewing(false);
    setScheduleViewingInputData(initialScheduleViewingInputData);
    setScheduleViewingInputDataValidation(initialScheduleViewingInputDataVal);
  };

  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  const hideDropHintModal = () => {
    setShowDropModal(false);
    setDropHintInputData(initialDropHintInputData);
    setDropHintInputDataValidation(initialDropHintInputDataVal);
  };

  const closeRequestMoreModal = () => {
    setRequestMoreModal(false);
    setRequestMoreInfoInputData(initialRequestMoreInfoData);
    setRequestMoreInfoInputDataValidation(initialRequestMoreInfoDataVal);
  };

  const handleSubmitSheduleViewing = () => {
    let isValid = handleValidationSchedule();
    if (isValid) {
      handleScrollToTop();
      // setConfirmScheduleModal(true)
      handleSaveScheduleViewing();
    }
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "recipientName") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        recipientNameval: "",
      }));
    }
    if (name == "recipientEmail") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        recipientEmailval: "",
      }));
    }
    if (name == "giftReason") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        giftReasonval: "",
      }));
    }
    if (name == "personalMessage") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
    if (name == "giftDeadline") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        giftDeadlineval: "",
      }));
    }
  };

  const handlepasswordMoreInfoChange = (e, name, type) => {
    setInternalUseData({
      ...internalUseData,
      [name]: e.target.value,
    });

    if (name == "password") {
      setInternalUseDataval((prevdata) => ({
        ...prevdata,
        passwordval: "",
      }));
    }
  };

  const handleImageSet = (data) => {
    setDisplayImage(data);
  };

  const showModal1 = () => {
    handleScrollToTop();
    setRequestMoreModal(true);
  };

  const showModal2 = () => {
    handleScrollToTop();
    setShowDropModal(true);
  };
  const showModal3 = () => {
    handleScrollToTop();
    setEmailFriendModal(true);
  };
  const showModal5 = () => {
    handleScrollToTop();
    setOpenScheduleViewing(true);
  };

  const handleSaveDropHint = async () => {
    let isValid = handleValidationDrop();

    if (!isValid) {
      setShowDropModal(true);
      return false;
    }

    let inputData = {
      loadFromMasterlink: true,
      strDiamondLinkURL: "",
      dealerID: dealerId,
      hintYourEmail: dropHintInputData.email,
      hintYourName: dropHintInputData.name,
      hintRecptEmail: dropHintInputData.recipientEmail,
      hintRecptName: dropHintInputData.recipientName,
      hintRecptReason: dropHintInputData.giftReason,
      hintRecpMessage: dropHintInputData.personalMessage,
      hintRecptGiftDeadline: dropHintInputData.giftDeadline,
      sid: "",
      did: diamondDetails,
      shape: "",
      ctw: "",
      step: "",
      themeName: "WhiteTheme",
      viewItemURL: window.location.href.toString(),
      type: "Diamond",
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderDropHint(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              t("Your request has been sent to GemFind.")
            );
            setShowDropModal(false);
            setButtonLoading(false);
            setDropHintInputData(initialDropHintInputData);
          } else {
            NotificationManager.error(
              t("Your request has not been sent to GemFind.")
            );
            setShowDropModal(false);
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            t("Your request has not been sent to GemFind.")
          );
          setShowDropModal(false);
          setButtonLoading(false);
          setDropHintInputData(initialDropHintInputData);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error(t("Sorry we had an error"));
      setShowDropModal(false);
      setButtonLoading(false);
      setDropHintInputData(initialDropHintInputData);
      console.log(error);
    }
  };

  const handleValidationDrop = () => {
    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      personalMessage,
      giftDeadline,
    } = dropHintInputData;

    const dropHintInputDataValidation = {
      nameval: "",
      emailval: "",
      recipientNameval: "",
      recipientEmailval: "",
      giftReasonval: "",
      personalMessageval: "",
      giftDeadlineval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintInputDataValidation.nameval = t("Name is Compulsory");
    } else {
      dropHintInputDataValidation.nameval = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      dropHintInputDataValidation.emailval = t("Email is Compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintInputDataValidation.emailval = t("Email is Invalid");
    } else {
      dropHintInputDataValidation.emailval = "";
    }

    if (!recipientName.trim()) {
      isValid = false;
      dropHintInputDataValidation.recipientNameval = t(
        "Recipient's Name is Compulsory"
      );
    } else {
      dropHintInputDataValidation.recipientNameval = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintInputDataValidation.recipientEmailval = t(
        "Recipient's E-Mail is Compulsory"
      );
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintInputDataValidation.recipientEmailval = t(
        "Recipient's E-Mail is Invalid"
      );
    } else {
      dropHintInputDataValidation.recipientEmailval = "";
    }

    if (!giftReason.trim()) {
      isValid = false;
      dropHintInputDataValidation.giftReasonval = t("Reason is Compulsory");
    } else {
      dropHintInputDataValidation.giftReasonval = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintInputDataValidation.personalMessageval = t(
        "Personal Message is Compulsory"
      );
    } else {
      dropHintInputDataValidation.personalMessageval = "";
    }

    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintInputDataValidation.giftDeadlineval = t(
        "Gift Deadline is Compulsory"
      );
    } else {
      dropHintInputDataValidation.giftDeadlineval = "";
    }

    setDropHintInputDataValidation(dropHintInputDataValidation);
    return isValid;
  };

  const handleValidationReuestInfo = () => {
    const { name, email, phoneNumber } = requestMoreInfoInputData;

    const requestMoreInfoInputDataValidation = {
      nameval: "",
      emailval: "",
      phoneNumberval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.nameval = t("Name is Compulsory");
    } else {
      requestMoreInfoInputDataValidation.nameval = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.emailval = t("Email is Compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoInputDataValidation.emailval = t("Email is Invalid");
    } else {
      requestMoreInfoInputDataValidation.emailval = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.phoneNumberval = t(
        "Phone Number is Compulsory"
      );
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoInputDataValidation.phoneNumberval = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoInputDataValidation.phoneNumberval = t(
        "Phone number is invalid"
      );
    } else {
      requestMoreInfoInputDataValidation.phoneNumberval = "";
    }
    setRequestMoreInfoInputDataValidation(requestMoreInfoInputDataValidation);
    return isValid;
  };

  const handleValidationSchedule = () => {
    const {
      name,
      email,
      phoneNumber,
      location,
      appointmentDate,
      appointmentTime,
      message,
    } = scheduleViewingInputData;

    const scheduleViewingInputDataValidation = {
      nameval: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      scheduleViewingInputDataValidation.nameval = t("Name is Compulsory");
    } else {
      scheduleViewingInputDataValidation.nameval = "";
    }
    if (!location) {
      isValid = false;
      scheduleViewingInputDataValidation.locationVal = t(
        "Location is Compulsory"
      );
    }

    if (!appointmentDate) {
      isValid = false;
      scheduleViewingInputDataValidation.appointmentDateVal = t(
        "Appointment Date is Compulsory"
      );
    }

    if (!appointmentTime) {
      isValid = false;
      scheduleViewingInputDataValidation.appointmentTimeVal = t(
        "Appointment Time is Compulsory"
      );
    }

    if (!message) {
      isValid = false;
      scheduleViewingInputDataValidation.messageVal = t(
        "Message is Compulsory"
      );
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      isValid = false;
      scheduleViewingInputDataValidation.emailVal = t(
        "Email address is compulsory"
      );
    } else if (!email.match(validRegex)) {
      isValid = false;
      scheduleViewingInputDataValidation.emailVal = t(
        "Email address is invalid"
      );
    } else {
      scheduleViewingInputDataValidation.emailVal = "";
    }
    if (!phoneNumber) {
      isValid = false;
      scheduleViewingInputDataValidation.phoneNumberVal = t(
        "Phone number is compulsory"
      );
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      scheduleViewingInputDataValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      scheduleViewingInputDataValidation.phoneNumberVal = t(
        "Phone number is invalid"
      );
    } else {
      scheduleViewingInputDataValidation.phoneNumberVal = "";
    }

    setScheduleViewingInputDataValidation(scheduleViewingInputDataValidation);
    return isValid;
  };

  const handleSubmitDropHint = () => {
    handleSaveDropHint();
  };

  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      certInfoValidation.nameVal = t("First Name is Compulsory");
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      isValid = false;
      certInfoValidation.emailVal = t("Email address is compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = t("Email address is invalid");
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber) {
      isValid = false;
      certInfoValidation.phoneNumberVal = t("Phone number is compulsory");
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = t("Phone number is invalid");
    } else {
      certInfoValidation.phoneNumberVal = "";
    }

    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };

  const handleSaveRequestCertificate = async () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: diamondInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: diamondInfoDetails.dealerID,
      };

      setRequestMoreCertModalLoading(true);
      await SelectYourDiamondService.RingBuilderRequestCertificate(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message == "Success") {
            NotificationManager.success(
              t(
                "Your request has been sent to GemFind. A representative will be contacting you shortly."
              )
            );
            setcertInfoData(initialCertInfoInputData);
            setcertInfoDataValidation(initialCertInfoInputDataVal);
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          } else {
            NotificationManager.success(
              t(
                "Your request has been sent to GemFind. A representative will be contacting you shortly."
              )
            );
            setcertInfoData(initialCertInfoInputData);
            setcertInfoDataValidation(initialCertInfoInputDataVal);
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setcertInfoData(initialCertInfoInputData);
          setcertInfoDataValidation(initialCertInfoInputDataVal);
          setRequestMoreCertModalLoading(false);
          setRequestMoreCertModal(false);
        });
    } catch (error) {
      console.log(error);
      setcertInfoData(initialCertInfoInputData);
      setcertInfoDataValidation(initialCertInfoInputDataVal);
      setRequestMoreCertModalLoading(false);
      setRequestMoreCertModal(false);
    }
  };

  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "phoneNumber") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberval: "",
      }));
    }
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
  };

  const handleSaveRequestMoreInfo = async () => {
    let isValid = handleValidationReuestInfo();

    if (!isValid) {
      setRequestMoreModal(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId,
      email: requestMoreInfoInputData.email,
      phone: requestMoreInfoInputData.phoneNumber,
      byEmail:
        requestMoreInfoInputData.contactPreference == "byEmail" ? true : false,
      comments: requestMoreInfoInputData.personalMessage,
      name: requestMoreInfoInputData.name,
      did: diamondDetails,
      retailerID: DealerIDForSelectedDiamond,
      type: "Diamond",
      viewThisItemURL: window.location.href,
      price: createValidPrice(diamondInfoDetails?.price),
      priceWithCurrency:
        diamondInfoDetails?.currencycode +
        diamondInfoDetails?.currencySign +
        parseInt(diamondInfoDetails.price)
          .toFixed()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .toString(),
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderRequestMoreInfo(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              t(
                "Your request has been sent to GemFind. A representative will be contacting you shortly."
              )
            );
            setButtonLoading(false);
            setRequestMoreModal(false);
            setRequestMoreInfoInputData(initialRequestMoreInfoData);
          } else {
            NotificationManager.error(
              t("Your request has not been sent to GemFind.")
            );
            setButtonLoading(false);
            setRequestMoreModal(false);
            setRequestMoreInfoInputData(initialRequestMoreInfoData);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            t("Your request has not been sent to GemFind")
          );
          setButtonLoading(false);
          setRequestMoreModal(false);
          setRequestMoreInfoInputData(initialRequestMoreInfoData);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error(t("Sorry we had an error"));
      setButtonLoading(false);
      setRequestMoreModal(false);
      setRequestMoreInfoInputData(initialRequestMoreInfoData);
      console.log(error);
    }
  };
  function createValidPrice(price) {
    if (callForPrice || !price || price == 0) {
      return "Call For Price";
    } else {
      return price.toFixed(2).toString();
    }
  }
  const handleSaveEmailFriend = async () => {
    let isValid = handleValidationEmailFriend();

    if (!isValid) {
      setEmailFriendModal(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId,
      yourEmail: emailFriendInputData.email,
      tellFriendComment: emailFriendInputData.personalMessage,
      yourName: emailFriendInputData.name,
      yourFriendEmail: emailFriendInputData.friendEmail,
      yourFriendName: emailFriendInputData.friendName,
      did: diamondDetails,
      retailerID: DealerIDForSelectedDiamond,
      byEmail: true,
      viewThisItemURL: window.location.href,
      type: "Diamond",
      price: createValidPrice(diamondInfoDetails?.price),
      priceWithCurrency:
        diamondInfoDetails?.currencycode +
        diamondInfoDetails?.currencySign +
        parseInt(diamondInfoDetails.price)
          .toFixed()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .toString(),
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderEmailToFriend(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              t("An email has been sent to your friend,Thanks for sharing!")
            );
            setButtonLoading(false);
            setEmailFriendModal(false);
            setEmailFriendInputData(initialEmailFriendInputData);
          } else {
            NotificationManager.error(
              t("An email has been not sent to your friend")
            );
            setButtonLoading(false);
            setEmailFriendModal(false);
            setEmailFriendInputData(initialEmailFriendInputData);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            t("An email has not been  sent to your friend,Thanks for sharing!")
          );
          setButtonLoading(false);
          setEmailFriendModal(false);
          setEmailFriendInputData(initialEmailFriendInputData);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error(t("Sorry we had an error"));
      setButtonLoading(false);
      setEmailFriendModal(false);
      setEmailFriendInputData(initialEmailFriendInputData);
      console.log(error);
    }
  };

  const handleValidationEmailFriend = () => {
    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;

    const emailFriendInputDataValidation = {
      nameval: "",
      emailval: "",
      friendNameval: "",
      friendEmailval: "",
      personalMessageval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputDataValidation.nameval = t("Name is Compulsory");
    } else {
      emailFriendInputDataValidation.nameval = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputDataValidation.emailval = t("Email is Compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputDataValidation.emailval = t("Email is Invalid");
    } else {
      emailFriendInputDataValidation.emailval = "";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputDataValidation.friendNameval = t(
        "Friend's Name is Compulsory"
      );
    } else {
      emailFriendInputDataValidation.friendNameval = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputDataValidation.friendEmailval = t(
        "Friend's E-Mail is Compulsory"
      );
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputDataValidation.friendEmailval = t(
        "Friend's E-Mail is Invalid"
      );
    } else {
      emailFriendInputDataValidation.friendEmailval = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputDataValidation.personalMessageval = t(
        "Personal Message is Compulsory"
      );
    } else {
      emailFriendInputDataValidation.personalMessageval = "";
    }
    setEmailFriendInputDataValidation(emailFriendInputDataValidation);
    return isValid;
  };

  const handleSubmitRequestMoreInfo = () => {
    handleSaveRequestMoreInfo();
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "friendName") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendNameval: "",
      }));
    }

    if (name == "friendEmail") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendEmailval: "",
      }));
    }

    if (name == "personalMessage") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
  };

  const handleSubmitEmailFriend = () => {
    handleSaveEmailFriend();
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });

      if (name == "location") {
        handleGetDateandTime(
          dealerId,
          "",
          scheduleViewingInputData.appointmentDate,
          e
        );
      }
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });

      if (name == "appointmentDate") {
        handleGetDateandTime(
          dealerId,
          "",
          moment(e.target.value).format("MM/DD/YYYY"),
          scheduleViewingInputData.location
        );
      }
    }

    if (name == "name") {
      setScheduleViewingInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
  };

  const handleGetDateandTime = (id, theme, appoint, location) => {
    try {
      let inputData = {
        dealerLink: id,
        themeName: theme,
        appointDate: appoint,
        locationID: location.toString(),
      };
      ChooseViewDetailsServices.BindTimeDropDown(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message == "Success") {
            setGetTimeDate(responseData.responseData.HTMLDataTime.data);
          } else {
            setLoading(false);
            setGetTimeDate([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSaveScheduleViewing = async () => {
    // let isValid = handleValidationSchedule();

    // if (!isValid) {
    //   setOpenScheduleViewing(true);
    //   return false;
    // }
    let valuePrice = parseInt(diamondInfoDetails.price);
    let actualValue = `${currencySymbol}${valuePrice}`;
    let inputData = {
      retailerID: DealerIDForSelectedDiamond,
      dealerID: dealerId,
      emailChk: scheduleViewingInputData.email,
      schedulPnone: scheduleViewingInputData.phoneNumber,
      appntMsg: scheduleViewingInputData.message,
      schedulName: scheduleViewingInputData.name,
      appntDate: scheduleViewingInputData.appointmentDate,
      hndAppntTime: scheduleViewingInputData.appointmentTime,
      did: diamondDetails,
      shape: "",
      ctw: "",
      strDiamondLinkURL: "",
      step: "",
      themeName: "",
      loadFromMasterlink: true,
      viewThisItemURL: window.location.href,
      type: "Diamond",
      location: scheduleViewingInputData.location.toString(),
      price: createValidPrice(diamondInfoDetails?.price),
      diamondPriceWithCurrency:
        diamondInfoDetails?.currencycode + actualValue.toString() || "",
      priceWithCurrency:
        diamondInfoDetails?.currencycode +
        diamondInfoDetails?.currencySign +
        parseInt(diamondInfoDetails.price)
          .toFixed()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .toString(),
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderScheduleviewing(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              t("Your request has been sent to GemFind.")
            );
            setButtonLoading(false);
            setOpenScheduleViewing(false);
            setScheduleViewingInputData(initialScheduleViewingInputData);
            setConfirmScheduleModal(false);
          } else {
            NotificationManager.error(
              t("Your request has not been sent to GemFind.")
            );
            setButtonLoading(false);
            setOpenScheduleViewing(false);
            setScheduleViewingInputData(initialScheduleViewingInputData);
            setConfirmScheduleModal(false);
          }
        })
        .catch((error) => {
          NotificationManager.success(
            t("Your request has been sent to GemFind.")
          );
          setButtonLoading(false);
          setOpenScheduleViewing(false);
          setScheduleViewingInputData(initialScheduleViewingInputData);
          setConfirmScheduleModal(false);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error(t("Sorry we had an error"));
      setButtonLoading(false);
      setOpenScheduleViewing(false);
      setScheduleViewingInputData(initialScheduleViewingInputData);
      console.log(error);
      setConfirmScheduleModal(false);
    }
  };

  const handleValidationPassworsInfo = () => {
    const { password } = internalUseData;

    const internalUseDataval = {
      passwordval: "",
    };
    let isValid = true;

    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );

    if (!password.trim()) {
      isValid = false;
      internalUseDataval.passwordval = t("Password is Compulsory");
    } else if (!password.match(strongRegex)) {
      isValid = false;
      internalUseDataval.passwordval = t(
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"
      );
    } else {
      internalUseDataval.passwordval = "";
    }
    setInternalUseDataval(internalUseDataval);
    return isValid;
  };

  const handleGetDealerInformation = () => {
    let isValid = handleValidationPassworsInfo();

    if (!isValid) {
      setInternalUseModal(true);
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        diamondDealerID: diamondInfoDetails.dealerID,
        password: internalUseData.password,
        diamondID: diamondDetails,
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformation(inputData)
        .then((response) => {
          let message = response?.data?.responseData?.passwordcheck;
          let dealerInfo = response?.data?.responseData?.getDiamondInformation;
          if (message == false) {
            NotificationManager.error(t("Please enter valid password"));
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setInternalUseModalLoading(false);
            setInternalUseData({
              password: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
          setInternalUseData({
            password: "",
          });
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
      setInternalUseData({
        password: "",
      });
    }
  };
  const handleSubmitInternalDataHide = () => {
    setInternalButtonSave(false);
    setInternalUseModal(false);
    setShowDealerInfo(false);
  };

  const HandleCallForInternal = () => {
    {
      internalButtonSave
        ? handleSubmitInternalDataHide()
        : handleSubmitInternalData();
    }
  };

  const handleSubmitInternalData = () => {
    handleGetDealerInformation();
    setInternalButtonSave(true);
    setShowDealerInfo(false);
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);
    setInternalUseData({
      password: "",
    });
    setInternalUseDataval({
      passwordval: "",
    });
    setInternalButtonSave(false);
  };
  const handleOpenInternalModal = () => {
    handleScrollToTop();
    setInternalUseModal(true);
  };

  function handlePrintDiamondList() {
    window.print();
  }

  const handleCompleteYourRing = () => {
    dispatch(handleActiveTab("completeYourRing"));
  };

  const handleAddYourSetting = (details) => {
    let obj = details;
    dispatch(handleViewDetailsPage(obj));
    dispatch(handleHideEditChooseThisMounting());
    navigate(
      "/chooseYourSetting" +
        `?ischooseYourSetting=True&isselectyourdiamond=false&DealerID=${paramdealerId}&DiamondId=${diamondId}&pageName=${socialpageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${socialtxtCutlet}&txtClarity=${socialtxtClarity}&intColorPriority=${sociaColorPriority}`
    );
  };

  const handleGetSimilarDiamond = (dealerId, DiamondDetails, page) => {
    setRowDetails(DiamondDetails);
    setPageName(DiamondDetails.pageName);
    handleSimilarDiamondList(
      dealerId,
      DiamondDetails,
      page,
      DiamondDetails.pageName,
      "",
      pageSize,
      sortColumnName,
      sortType
    );
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleSimilarDiamondList(
        dealerId,
        completeData,
        pageNo,
        pageName,
        "",
        pageSize,
        sortColumnName,
        sortType
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleSimilarDiamondList(
        dealerId,
        completeData,
        current,
        pageName,
        "",
        pageNewSize,
        sortColumnName,
        sortType
      );
    }
  }

  const handleSimilarDiamondList = (
    id,
    DiamondDetails,
    page,
    PageName,
    value,
    pageSize,
    column,
    type
  ) => {
    let a = {};

    let ValColor = 0;
    let ValClarity = 0;
    let ValCut = 0;
    if (DiamondDetails.intColorPriority == "D") {
      ValColor = 68;
    } else if (DiamondDetails.intColorPriority == "E") {
      ValColor = 69;
    } else if (DiamondDetails.intColorPriority == "F") {
      ValColor = 70;
    } else if (DiamondDetails.intColorPriority == "G") {
      ValColor = 71;
    } else if (DiamondDetails.intColorPriority == "H") {
      ValColor = 72;
    } else if (DiamondDetails.intColorPriority == "I") {
      ValColor = 73;
    } else if (DiamondDetails.intColorPriority == "J") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "K") {
      ValColor = 75;
    } else if (DiamondDetails.intColorPriority == "L") {
      ValColor = 76;
    } else if (DiamondDetails.intColorPriority == "M") {
      ValColor = 77;
    } else if (DiamondDetails.intColorPriority == "N") {
      ValColor = 78;
    } else if (DiamondDetails.intColorPriority == "O") {
      ValColor = 79;
    } else if (DiamondDetails.intColorPriority == "P") {
      ValColor = 80;
    } else {
      ValColor = 81;
    }
    let ValColorLess = ValColor - 1;
    let ValColorMore = ValColor + 1;
    let ColorList = "";
    if (ValColorLess <= 68) {
      ColorList = ValColor + "," + ValColorMore;
    } else if (ValColorMore > 77) {
      ColorList = ValColorLess + "," + ValColor;
    } else if (ValColorMore > 77 && ValColorLess <= 68) {
      ColorList = ValColor;
    } else {
      ColorList = ValColorLess + "," + ValColor + "," + ValColorMore;
    }
    if (DiamondDetails.txtClarity == "FL") {
      ValClarity = 1;
    } else if (DiamondDetails.txtClarity == "IF") {
      ValClarity = 2;
    } else if (DiamondDetails.txtClarity == "VVS1") {
      ValClarity = 3;
    } else if (DiamondDetails.txtClarity == "VVS2") {
      ValClarity = 4;
    } else if (DiamondDetails.txtClarity == "VS1") {
      ValClarity = 5;
    } else if (DiamondDetails.txtClarity == "VS2") {
      ValClarity = 6;
    } else if (DiamondDetails.txtClarity == "SI1") {
      ValClarity = 7;
    } else if (DiamondDetails.txtClarity == "SI2") {
      ValClarity = 8;
    } else if (DiamondDetails.txtClarity == "SI3") {
      ValClarity = 9;
    } else if (DiamondDetails.txtClarity == "I1") {
      ValClarity = 10;
    } else if (DiamondDetails.txtClarity == "I2") {
      ValClarity = 11;
    } else {
      ValClarity = 12;
    }
    let ClarityList = "";
    let ValClarityLess = ValClarity - 1;
    let ValClarityMore = ValClarity + 1;

    if (ValClarityLess <= 1) {
      ClarityList = ValClarity + "," + ValClarityMore;
    } else if (ValClarityMore > 10) {
      ClarityList = ValClarityLess + "," + ValClarity;
    } else if (ValClarityMore > 1 && ValClarityLess <= 10) {
      ClarityList = ValClarityLess + "," + ValClarity + "," + ValClarityMore;
    } else {
      ClarityList = ValClarityMore + "," + ValClarity + "," + ValClarityLess;
    }
    if (DiamondDetails?.txtCutGrade?.toLowerCase() == "ideal") {
      ValCut = 1;
    } else if (DiamondDetails?.txtCutGrade?.toLowerCase() == "excellent") {
      ValCut = 2;
    } else if (DiamondDetails?.txtCutGrade?.toLowerCase() == "very good") {
      ValCut = 3;
    } else if (DiamondDetails?.txtCutGrade?.toLowerCase() == "good") {
      ValCut = 4;
    } else if (DiamondDetails?.txtCutGrade?.toLowerCase() == "fair") {
      ValCut = 5;
    } else {
      ValCut = 6;
    }

    let CutList = "";
    let ValCutLess = ValCut - 1;
    let ValCutMore = ValCut + 1;

    if (ValCutLess <= 1) {
      CutList = ValCut + "," + ValCutMore;
    } else if (ValCutMore > 7) {
      CutList = "";
    } else if (ValCutMore > 1 && ValCutLess <= 7) {
      ClarityList = ValCut;
    } else {
      ClarityList = ValCutMore + "," + ValCut + "," + ValCutLess;
    }
    try {
      let inputData;
      if (PageName == "Mined") {
        inputData = {
          dealerID: id.toString(),
          // shape:
          //   DiamondDetails.shapeFilters.length > 0
          //     ? DiamondDetails.shapeFilters.toString()
          //     : "",
          priceMin: "0",
          priceMax: "9999999",
          caratMin: "0",
          caratMax: "22",
          colorMin: "68",
          colorMax: "80",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "11", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "5",
          symmetryMin: "1",
          symmetryMax: "4",
          polishMin: "1",
          polishMax: "4",
          fluorescenceMin: "1",
          fluorescenceMax: "5",
          certificate: "",
          skuNo: value,
          pairCode: "",
          pageSize: pageSize.toString(),
          currentPage: page.toString(),
          orderBy:
            column == "txtShape"
              ? "Cut"
              : column == "fltCarat"
              ? "Size"
              : column == "txtColor"
              ? "Color"
              : column == "txtClarity"
              ? "Clarity"
              : column == "txtCutGrade"
              ? "CutGrade"
              : column == "fltDepth"
              ? "Depth"
              : column == "fltTable"
              ? "TableMeasure"
              : column == "txtPolish"
              ? "Polish"
              : column == "txtSymmetry"
              ? "Symmetry"
              : column == "txtMeasurements"
              ? "Measurements"
              : column == "txtCertificate"
              ? "Certificate"
              : column == "fltPrice"
              ? "FltPrice"
              : "FltPrice",
          orderType: type ? type : "ASC",
          detailsLink: "pendantBuilderDiamondDetail.aspx",
          DID: "",
          depthMin: "",
          depthMax: "100",
          tableMin: "",
          tableMax: "100",
          caratPriceMin: "",
          caratPriceMax: "",
          ThemeName: "WhiteTheme",
          ShowPrice: "false",
          BindWhat: "All",
          polishList: "",
          SymmetryList: "",
          FluorescenceList: "",
          ExtraQStr: "&SID=&Shape=&CTW=&Size=",
          ColorList: ValColor?.toString(), // Logic from above input
          ClarityList: ValClarity?.toString(), //Logic from above input
          BindCheckbox: "1",
          SOrigin: "",
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          CutGradeList: ValCut?.toString(),
          Isdiamond: 0,
          CustID: "",
          Track: "0",
          CountryTaxRate: "",
          CountryCode: "",
          IsFB: 0,
          IsMLPB: 0,
        };
      }
      if (PageName == "Lab") {
        inputData = {
          dealerID: id.toString(),
          // shape:
          //   DiamondDetails.shapeFilters.length > 0
          //     ? DiamondDetails.shapeFilters.toString()
          //     : "",
          priceMin: "0",
          priceMax: "9999999",
          caratMin: "0",
          caratMax: "22",
          colorMin: "68",
          colorMax: "80",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "11", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "5",
          symmetryMin: "1",
          symmetryMax: "4",
          polishMin: "1",
          polishMax: "4",
          fluorescenceMin: "1",
          fluorescenceMax: "5",
          certificate: "",
          skuNo: value,
          pairCode: "",
          pageSize: pageSize.toString(),
          currentPage: page.toString(),
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          CutGradeList: ValCut?.toString(),
          orderBy:
            column == "txtShape"
              ? "Cut"
              : column == "fltCarat"
              ? "Size"
              : column == "txtColor"
              ? "Color"
              : column == "txtClarity"
              ? "Clarity"
              : column == "txtCutGrade"
              ? "CutGrade"
              : column == "fltDepth"
              ? "Depth"
              : column == "fltTable"
              ? "TableMeasure"
              : column == "txtPolish"
              ? "Polish"
              : column == "txtSymmetry"
              ? "Symmetry"
              : column == "txtMeasurements"
              ? "Measurements"
              : column == "txtCertificate"
              ? "Certificate"
              : column == "fltPrice"
              ? "FltPrice"
              : "FltPrice",
          orderType: type ? type : "ASC",
          detailsLink: "pendantBuilderDiamondDetail.aspx",
          DID: "",
          depthMin: "",
          depthMax: "100",
          tableMin: "",
          tableMax: "100",
          caratPriceMin: "",
          caratPriceMax: "",
          ThemeName: "WhiteTheme",
          ShowPrice: "false",
          BindWhat: "All",
          polishList: "",
          SymmetryList: "",
          FluorescenceList: "",
          ExtraQStr: "&SID=&Shape=&CTW=&Size=",
          ColorList: ValColor?.toString(), // Logic from above input
          ClarityList: ValClarity?.toString(), //Logic from above input
          BindCheckbox: "1",
          SOrigin: "",
          Isdiamond: 0,
          CustID: "",
          Track: "0",
          CountryTaxRate: "",
          CountryCode: "",
          IsFB: 0,
          IsMLPB: 0,
        };
      }
      if (PageName == "FancyColor") {
        inputData = {
          dealerID: id.toString(),
          shape:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          certificate: "",
          skuNo: value,
          pairCode: "",
          pageSize: pageSize.toString(),
          currentPage: page.toString(),
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          sOrigin: "",
          isdiamond: 0,
          custID: "",
          track: "0",
          countryTaxRate: "",
          countryCode: "",
          isFB: 0,
          isMLPB: 0,
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          CutGradeList: "",
        };
      }

      setLoadingDiamondGrid(true);
      SelectYourDiamondService.GetSimilarDiamondsByPage(inputData, PageName)

        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.Table1;
          let newRe = response.data.responseData.Table1;
          let FirstRow = response.data.responseData.Table1[0];
          if (message == "Success") {
            if (Object.keys(newRe).length > 0) {
              setList(responseData);
              setTotalRowCount(FirstRow.intTotalRecords);
              setLoadingDiamondGrid(false);
            } else {
              setList([]);
              setTotalRowCount(0);
              setLoadingDiamondGrid(false);
            }
          } else {
            setList([]);
            setTotalRowCount(0);
            setLoadingDiamondGrid(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingDiamondGrid(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingDiamondGrid(false);
    }
  };

  const handleScrollToTop = () => {
    const element = document.getElementById("topHeader");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handleViewDetails = (details) => {
    let obj = details;
    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&isselectyourdiamond=false&DealerID=${paramdealerId}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${socialpageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    setSimilarToogle(!similarToogle);
    handleScrollToTop();
  };

  const handleShareIconsView = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const handleGetDealerLocationData = (id, retailerId) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        myRetailerID: retailerId,
      };
      setLoading(true);
      SelectYourDiamondService.GetDealerLocationData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          let newRe = response.data.responseData[0];

          if (message == "Success") {
            if (Object.keys(newRe).length > 0) {
              setLocationData({
                locationName: responseData.locationName,
                phone: responseData.phone,
                emailID: responseData.emailID,
              });

              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleBackButton = (e) => {
    if (pageName == "Fancy Colored") {
      navigate("/Fancy" + `?DealerLink=${paramdealerId}`);
    } else if (pageName == "Lab") {
      navigate("/Lab" + `?DealerLink=${paramdealerId}`);
    } else {
      navigate("/Mined" + `?DealerLink=${paramdealerId}`);
    }
    dispatch(handleComaparePageIds(""));
  };

  return (
    <React.Fragment>
      <Wrapper>
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <div className="row m-0">
            <div className="col-md-12">
              <span
                className="back__btn linkText"
                onClick={(e) => handleBackButton(e)}
              >
                {" <<"}
                {t("Change Diamond")}{" "}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="form__fields border-0 p-1">
                  <div className="col-md-12">
                    <div
                      className="prod__maindiv app__preview p-0 border-0"
                      id="printsection"
                    >
                      <div className="row">
                        {viewStateLoading ? (
                          <Spin spinning={viewStateLoading} />
                        ) : (
                          <>
                            <div className="col-lg-6 col-md-6">
                              <div className="row">
                                <div className="whole__box">
                                  <div className="diamond__block">
                                    <p>{t("Diamond")} 1</p>
                                    <div className="image__thbox">
                                      <div className="box__one">
                                        <img
                                          onClick={() =>
                                            handleImageSet(mountDetails.image)
                                          }
                                          src={mountDetails.image}
                                        />
                                      </div>
                                      <div className="box__one">
                                        <img
                                          onClick={() =>
                                            handleImageSet(
                                              mountDetails.sizeimage
                                            )
                                          }
                                          src={mountDetails.sizeimage}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="big__img thimage__slider">
                                    <img
                                      src={
                                        displayImage
                                          ? displayImage
                                          : mountDetails.image
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="diamond__detail">
                                    <div className="row">
                                      <div className="col-md-12 mb-2">
                                        <div>
                                          <span>
                                            {t("Diamond Grading Report")}
                                          </span>

                                          {diamondInfoDetails.certificate ==
                                          "" ? (
                                            <span
                                              className="linkText ml-2"
                                              onClick={() =>
                                                showCertInfoModal()
                                              }
                                            >
                                              Request Certificate
                                            </span>
                                          ) : (
                                            <span
                                              className="linkText ml-2"
                                              onClick={() => showCertModal()}
                                            >
                                              {t("View")}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        {diamondInfoDetails.certificate !==
                                        "" ? (
                                          diamondInfoDetails.certificate ==
                                          "GIA" ? (
                                            <img src={GIA} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "IGI" ? (
                                            <img src={IGI} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "GIA" ? (
                                            <img src={GIA} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "AGS" ? (
                                            <img src={AGS} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "EGL" ? (
                                            <img src={EGL} width="70px" />
                                          ) : (
                                            <img src={IMG5} width="70px" />
                                          )
                                        ) : (
                                          <img src={IMG5} width="70px" />
                                        )}
                                      </div>
                                      <div className="col-md-10">
                                        <p>
                                          {t("This diamond is graded by")}{" "}
                                          {diamondInfoDetails.certificate
                                            ? diamondInfoDetails.certificate
                                            : "NA"}
                                          .{" "}
                                          {t(
                                            "This diamond certificate is your assurance that the diamond meets all gemological standards to the quality grade shown in the certification report."
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="prodesc__div">
                                <Tabs
                                  defaultActiveKey="home"
                                  id="uncontrolled-tab-example"
                                  className="tab__div border-0"
                                >
                                  <Tab
                                    eventKey="home"
                                    title={t("Item Details")}
                                  >
                                    <Spin spinning={viewStateLoading}>
                                      <div className="tab__contentdiv border__block p-0">
                                        <div className="head__div">
                                          <h4>
                                            {diamondInfoDetails.carat}-
                                            {t("Carat")}{" "}
                                            {diamondInfoDetails.cut}{" "}
                                            {t("Diamond")}
                                          </h4>
                                        </div>
                                        <div className="desc__maindiv">
                                          <div className="row top__content m-0">
                                            <div className="col-md-12 mt-3">
                                              <div className="row">
                                                <div className="col-md-8">
                                                  <p>
                                                    {t("This")}{" "}
                                                    {diamondInfoDetails.cut
                                                      ? diamondInfoDetails.cut
                                                      : "NA"}
                                                    -{t("Cut")},
                                                    {diamondInfoDetails.color
                                                      ? diamondInfoDetails.color
                                                      : "NA"}
                                                    -{t("color")}, {t("and")}{" "}
                                                    {diamondInfoDetails.clarity
                                                      ? diamondInfoDetails.clarity
                                                      : "NA"}
                                                    -{" "}
                                                    {t(
                                                      "clarity diamond comes accompanied by a diamond grading report from"
                                                    )}{" "}
                                                    {diamondInfoDetails.certificate
                                                      ? diamondInfoDetails.certificate
                                                      : "NA"}
                                                  </p>
                                                </div>
                                                <div className="col-md-4">
                                                  <p className="text-right text-left-mobile">
                                                    SKU#{" "}
                                                    <span className="linkText">
                                                      <Tooltip
                                                        title={
                                                          diamondInfoDetails.skuNo ||
                                                          "NA"
                                                        }
                                                      >
                                                        {diamondInfoDetails.skuNo
                                                          ? diamondInfoDetails.skuNo.slice(
                                                              0,
                                                              10
                                                            ) +
                                                            (diamondInfoDetails
                                                              .skuNo.length > 10
                                                              ? "..."
                                                              : "")
                                                          : "NA"}
                                                      </Tooltip>
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-3 col-6">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  {t("Report")}
                                                </p>
                                                <p>
                                                  {diamondInfoDetails.certificate
                                                    ? diamondInfoDetails.certificate
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-3 col-6">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  {t("Cut")}
                                                </p>
                                                <p>
                                                  {diamondInfoDetails.cutGrade
                                                    ? diamondInfoDetails.cutGrade
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-12 mobile__version">
                                              <hr />
                                            </div>

                                            <div className="col-md-3 col-6">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  {t("Color")}
                                                </p>
                                                <p>
                                                  {diamondInfoDetails.color
                                                    ? diamondInfoDetails.color
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-3 col-6">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  {t("Clarity")}
                                                </p>
                                                <p>
                                                  {" "}
                                                  {diamondInfoDetails.clarity
                                                    ? diamondInfoDetails.clarity
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-12 mobile__version">
                                              <hr />
                                            </div>

                                            <div className="col-md-12">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  {callForPrice ? (
                                                    <span className="subheading">
                                                      {t("Call For Price")}
                                                    </span>
                                                  ) : diamondInfoDetails.price ==
                                                    "0" ? (
                                                    <>
                                                      Call{" "}
                                                      <span className="hideInMobile">
                                                        For Price
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <span className="subheading">
                                                      {diamondInfoDetails?.currencycode ??
                                                        ""}{" "}
                                                      {currencySymbol}
                                                      {parseInt(
                                                        diamondInfoDetails.price
                                                      )
                                                        .toFixed()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )}
                                                    </span>
                                                  )}
                                                </p>
                                              </div>

                                              {currentTab &&
                                              currentTab.isSettingViewEdit ? (
                                                <button
                                                  onClick={
                                                    handleCompleteYourRing
                                                  }
                                                  className="primary-btn mr-2 mb-2"
                                                >
                                                  {t("Complete Your Ring")}
                                                </button>
                                              ) : (
                                                <button
                                                  className="primary-btn mr-2 mb-2"
                                                  onClick={() =>
                                                    handleAddYourSetting(
                                                      completeData
                                                    )
                                                  }
                                                >
                                                  {t("Add To Your Pendant")}
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="desc__maindiv">
                                          <div className="row top__content m-0">
                                            <div className="row itemdetails__btns p-0">
                                              <div className="col-sm-12 col-md-6">
                                                <p onClick={showModal2}>
                                                  <span className="img__box">
                                                    <img src={drophint} />
                                                  </span>
                                                  <span>
                                                    {t("Drop A Hint")}
                                                  </span>
                                                </p>
                                              </div>

                                              <div className="col-sm-12 col-md-6">
                                                <p onClick={showModal1}>
                                                  <span className="img__box">
                                                    <img src={infoblack} />
                                                  </span>
                                                  <span>
                                                    {t("Request More Info")}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>

                                            <div className="row itemdetails__btns p-0">
                                              <div className="col-sm-12 col-md-6">
                                                <p onClick={showModal3}>
                                                  <span className="img__box">
                                                    <img src={emailicon} />
                                                  </span>
                                                  <span>
                                                    {t("E-Mail A Friend")}
                                                  </span>
                                                </p>
                                              </div>

                                              {scheduleHide ? (
                                                <div className="col-sm-12 col-md-6">
                                                  <p onClick={showModal5}>
                                                    <span className="img__box">
                                                      <img src={calender} />
                                                    </span>
                                                    <span>
                                                      {t("Schedule Viewing")}
                                                    </span>
                                                  </p>
                                                </div>
                                              ) : null}
                                            </div>

                                            <div className="row itemdetails__btns p-0">
                                              <div className="col-sm-12 col-md-6">
                                                <p
                                                  onClick={() => {
                                                    handlePrintDiamondList();
                                                  }}
                                                >
                                                  <span className="img__box">
                                                    <img src={printicon} />
                                                  </span>
                                                  <span>
                                                    {t("Print Details")}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-12">
                                              <p>
                                                {t(
                                                  "Have a question regarding this item? Our specialists are available to assist you."
                                                )}
                                              </p>
                                            </div>
                                            <Spin spinning={loading}>
                                              {adressHide ? (
                                                <div className="col-md-12">
                                                  <p className="font__bold d-flex justify-between mb-0">
                                                    <span className="font__bold">
                                                      {locationData.locationName
                                                        ? parse(
                                                            locationData.locationName
                                                          )
                                                        : ""}
                                                    </span>
                                                  </p>
                                                  <p className="font__bold mb-0">
                                                    <span className="font__bold">
                                                      {locationData.phone
                                                        ? locationData.phone
                                                        : ""}
                                                    </span>
                                                  </p>
                                                  <p className="font__bold">
                                                    <a
                                                      href={`mailto:${locationData.emailID}`}
                                                    >
                                                      {locationData.emailID
                                                        ? locationData.emailID
                                                        : ""}
                                                    </a>
                                                  </p>
                                                </div>
                                              ) : null}
                                            </Spin>

                                            <div className="col-md-12">
                                              <div className="dtl__socialsection">
                                                {facebookIcons ? (
                                                  <button className="dtl__socialbtn fb__btn">
                                                    <img
                                                      src={DtlFacebook}
                                                      onClick={() =>
                                                        handleShareIconsView(
                                                          mountDetails.facbookUrl
                                                        )
                                                      }
                                                    />
                                                  </button>
                                                ) : null}

                                                {pinterestIcons ? (
                                                  <button className="dtl__socialbtn pintrest">
                                                    <img
                                                      src={DtlPintrest}
                                                      onClick={() =>
                                                        handleShareIconsView(
                                                          mountDetails.pinterestUrl
                                                        )
                                                      }
                                                    />
                                                  </button>
                                                ) : null}

                                                {twitterIcons ? (
                                                  <button className="dtl__socialbtn tweeter">
                                                    <img
                                                      src={DtlTwitter}
                                                      onClick={() =>
                                                        handleShareIconsView(
                                                          mountDetails.twitterUrl
                                                        )
                                                      }
                                                    />
                                                  </button>
                                                ) : null}
                                                {/* {instaGramIcons ? (
                                                  <button className="dtl__socialbtn instagram">
                                                    <img
                                                      src={DtlInstagram}
                                                      onClick={() =>
                                                        handleShareIconsView(
                                                          mountDetails.instagramUrl
                                                        )
                                                      }
                                                    />
                                                  </button>
                                                ) : null} */}
                                                {/* {facebookLikeIcons ? (
                                              <button className="dtl__socialbtn">
                                                <img
                                                  src={facebookLive}
                                                  className="fbLike"
                                                  onClick={() =>
                                                    handleShareIconsView(
                                                      mountDetails.facebookLikeUrl
                                                    )
                                                  }
                                                />
                                              </button>
                                            ) : null} */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Spin>
                                  </Tab>
                                  <Tab
                                    eventKey="profile"
                                    title={t("Specifications")}
                                  >
                                    <Spin spinning={viewStateLoading}>
                                      <div className="tab__contentdiv border__block p-0">
                                        <div className="head__div">
                                          <h4>{t("Diamond Details")}</h4>
                                        </div>
                                        <div className="desc__maindiv">
                                          {diamondDetails ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Stock Number")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.skuNo}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.price ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Price")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.price ==
                                                  "0" || callForPrice ? (
                                                  <span>Call For Price</span>
                                                ) : (
                                                  <span>
                                                    {diamondInfoDetails?.currencycode ??
                                                      ""}{" "}
                                                    {currencySymbol}
                                                    {parseInt(
                                                      diamondInfoDetails.price
                                                    )
                                                      .toFixed()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </span>
                                                )}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.pendantPricePerCarat ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Price Per Carat")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.price ==
                                                  "0" || callForPrice ? (
                                                  <span>Call For Price</span>
                                                ) : (
                                                  <>
                                                    {diamondInfoDetails?.currencycode ??
                                                      ""}{" "}
                                                    {currencySymbol}
                                                    {parseInt(
                                                      diamondInfoDetails.pendantPricePerCarat
                                                    )
                                                      .toFixed()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </>
                                                )}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.carat ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Carat Weight")}{" "}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.carat}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.cut ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Cut")}{" "}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.cutGrade}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.color ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Color")}{" "}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.color}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.clarity ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Clarity")}{" "}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.clarity}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.depth ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Depth")} %{" "}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.depth
                                                  ? diamondInfoDetails.depth +
                                                    " %"
                                                  : "NA"}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {diamondInfoDetails.tableMes ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Table")} %
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.tableMes
                                                  ? diamondInfoDetails.tableMes +
                                                    " %"
                                                  : "NA"}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.polish ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Polish")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.polish}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.symmetry ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Symmetry")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.symmetry}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {diamondInfoDetails.girdle ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Girdle")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.girdle}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.culet ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Culet")}
                                              </span>
                                              <span className="desc__txt">
                                                {diamondInfoDetails.culet}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.fluorescence ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Florescence")}
                                              </span>
                                              <span className="desc__txt">
                                                {
                                                  diamondInfoDetails.fluorescence
                                                }
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {diamondInfoDetails.measurements ? (
                                            <div className="desc__div">
                                              <span className="head__txt">
                                                {t("Measurement")}
                                              </span>
                                              <span className="desc__txt">
                                                {
                                                  diamondInfoDetails.measurements
                                                }
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </Spin>
                                  </Tab>
                                </Tabs>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="gview__div">
                                <span className="font__bold mr-2 similar__text">
                                  {TotalRowCount ? TotalRowCount : 0}{" "}
                                  {t("Similar Diamonds")}
                                </span>
                              </div>

                              <div>
                                <Search
                                  placeholder={t("Search Diamond Stock #")}
                                  onSearch={(e) =>
                                    handleSimilarDiamondList(
                                      dealerId,
                                      completeData,
                                      pageNo,
                                      pageName,
                                      e,
                                      pageSize,
                                      sortColumnName,
                                      sortType
                                    )
                                  }
                                  allowClear
                                  style={{ width: 250 }}
                                />
                              </div>
                            </div>
                            {internalUseLinkHide ? (
                              <div>
                                <span
                                  className="font__bold mr-2 mt-3 linkText w-auto"
                                  onClick={handleOpenInternalModal}
                                >
                                  {t("For Internal Use Only")}
                                </span>
                              </div>
                            ) : null}
                            <div className="col-md-12 mt-4">
                              <Spin spinning={loadingDiamondGrid}>
                                <div className="lviewprod__tbl">
                                  <Table
                                    columns={columns}
                                    className="info__tooltip__table"
                                    onChange={handleTableASCDES}
                                    onRow={(record, recordIndex) => ({
                                      onClick: (event) => {
                                        handleSetRowDetils(event, record);
                                      },
                                    })}
                                    rowClassName={(record) =>
                                      record.intDiamondId ==
                                      rowDetails.intDiamondId
                                        ? "row-active"
                                        : ""
                                    }
                                    dataSource={list}
                                    scroll={{ y: 500 }}
                                    pagination={false}
                                    headerClassName="sample"
                                  />
                                </div>

                                <div className="table__bottom__btn">
                                  <div>
                                    <Pagination
                                      current={pageNo}
                                      pageSize={pageSize}
                                      total={TotalRowCount}
                                      onChange={onChange}
                                      onShowSizeChange={onShowSizeChange}
                                      showSizeChanger="true"
                                    />
                                  </div>
                                </div>

                                <div className="custom__table print__table">
                                  <table class="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope="col">{t("Shape")}</th>
                                        <th scope="col">{t("Carats")}</th>
                                        <th scope="col">{t("Color")}</th>
                                        <th scope="col">{t("Clarity")}</th>
                                        <th scope="col">{t("Cut")}</th>
                                        <th scope="col">{t("Depth")}</th>
                                        <th scope="col">{t("Table")}</th>
                                        <th scope="col">{t("Polish")}</th>
                                        <th scope="col">{t("Symmetry")}</th>
                                        <th scope="col">{t("Measurement")}</th>
                                        <th scope="col">{t("Certificate")}</th>
                                        <th scope="col">{t("Price")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {list.map((row) => (
                                        <tr>
                                          <td>
                                            <img
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              src={row.ImageFileName}
                                              alt=""
                                            />
                                            <span>{row.txtShape}</span>
                                          </td>
                                          <td>
                                            <span>{row.fltCarat}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtColor}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtClarity}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtCutGrade}</span>
                                          </td>
                                          <td>
                                            <span>{row.fltDepth}</span>
                                          </td>
                                          <td>
                                            <span>{row.fltTable}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtPolish}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtSymmetry}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtMeasurements}</span>
                                          </td>
                                          <td>
                                            <span>{row.txtCertificate}</span>
                                          </td>
                                          <td>
                                            {callForPrice ? (
                                              <span>Call</span>
                                            ) : (
                                              <span>
                                                {row.fltPrice
                                                  ? `${
                                                      row?.currencycode ?? ""
                                                    }${currencySymbol}`
                                                  : null}
                                                {row.fltPrice}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Spin>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      <React.Fragment>
        {/* Modal Section Starts */}

        {/* Request More Info Modal Starts */}
        <Modal
          title={t("Request More Info")}
          visible={requestMoreModal}
          width={700}
          style={{ top: 20 }}
          className="request_info"
          maskClosable={false}
          onCancel={closeRequestMoreModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeRequestMoreModal}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              type="primary"
              onClick={handleSubmitRequestMoreInfo}
            >
              {t("Request")}
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>{t("Our specialist will contact you.")}</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-xs-12 col-md-12 col-lg-6">
                    <div className="input__block">
                      <label>
                        {t("Your Name")} <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={requestMoreInfoInputData.name}
                        placeholder={t("Enter Your Name")}
                        className={
                          requestMoreInfoInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "name", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12 col-lg-6">
                    <div className="input__block">
                      <label>
                        {t("Your E-Mail Address")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={requestMoreInfoInputData.email}
                        placeholder={t("Enter Your E-Mail Address")}
                        className={
                          requestMoreInfoInputDataValidation.emailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "email", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12 col-lg-6">
                    <div className="input__block">
                      <label>
                        {t("Your Phone Number")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={requestMoreInfoInputData.phoneNumber}
                        placeholder={t("Enter Your Phone Number")}
                        className={
                          requestMoreInfoInputDataValidation.phoneNumberval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "phoneNumber", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.phoneNumberval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.phoneNumberval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12 col-lg-6">
                    <div className="input__block">
                      <label>{t("Contact Preference")}</label>
                      <div className="row">
                        <div className="radio__block col-6">
                          <input
                            type="radio"
                            value="byEmail"
                            checked={
                              requestMoreInfoInputData.contactPreference ==
                              "byEmail"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "contactPreference",
                                "radio"
                              )
                            }
                          />

                          <label>{t("By Email")}</label>
                        </div>
                        <div className="radio__block col-6">
                          <input
                            type="radio"
                            value="byPhone"
                            checked={
                              requestMoreInfoInputData.contactPreference ==
                              "byPhone"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "contactPreference",
                                "radio"
                              )
                            }
                          />

                          <label>{t("By Phone")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>{t("Write A Personal Message")} ...</label>
                      <textarea
                        value={requestMoreInfoInputData.personalMessage}
                        placeholder={t("Write A Personal Message ...")}
                        onChange={(e) =>
                          handleRequestMoreInfoChange(
                            e,
                            "personalMessage",
                            "radio"
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Request More Info Modal Ends */}

        {/* Drop A Hint Modal Starts */}
        <Modal
          title={t("Drop A Hint")}
          width={700}
          visible={showDropModal}
          style={{ top: 20 }}
          className="drop_a_hint"
          maskClosable={false}
          onCancel={hideDropHintModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={hideDropHintModal}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              type="primary"
              loading={buttonLoading}
              onClick={handleSubmitDropHint}
            >
              {t("Drop Hint")}
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>{t("Because you deserve this.")}</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Name")} <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.name}
                        placeholder={t("Enter Your Name")}
                        className={
                          dropHintInputDataValidation.nameval && "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "name", "input")
                        }
                      />
                      {dropHintInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your E-Mail")} <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.email}
                        placeholder={t("Enter Your E-Mail")}
                        className={
                          dropHintInputDataValidation.emailval && "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "email", "input")
                        }
                      />
                      {dropHintInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Hint Recipient's Name")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.recipientName}
                        placeholder={t("Enter Hint Recipient's Name ")}
                        className={
                          dropHintInputDataValidation.recipientNameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "recipientName", "input")
                        }
                      />
                      {dropHintInputDataValidation.recipientNameval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.recipientNameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Hint Recipient's E-Mail")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.recipientEmail}
                        placeholder={t("Enter Hint Recipient's E-Mail ")}
                        className={
                          dropHintInputDataValidation.recipientEmailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "recipientEmail", "input")
                        }
                      />
                      {dropHintInputDataValidation.recipientEmailval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.recipientEmailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Reason For This Gift")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.giftReason}
                        placeholder={t("Enter Reason For This Gift")}
                        className={
                          dropHintInputDataValidation.giftReasonval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "giftReason", "input")
                        }
                      />
                      {dropHintInputDataValidation.giftReasonval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.giftReasonval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Gift Deadline")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        value={dropHintInputData.giftDeadline}
                        className={
                          dropHintInputDataValidation.giftDeadlineval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "giftDeadline", "date")
                        }
                        min={disablePastDate()}
                      />
                      {dropHintInputDataValidation.giftDeadlineval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.giftDeadlineval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        {t("Add A Personal Message Here")} ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={dropHintInputData.personalMessage}
                        placeholder={t("Add A Personal Message Here ...")}
                        className={
                          dropHintInputDataValidation.personalMessageval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "personalMessage", "input")
                        }
                      ></textarea>
                      {dropHintInputDataValidation.personalMessageval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.personalMessageval}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Drop A Hint Modal Ends */}

        {/* E-Mail A Friend Modal Starts */}
        <Modal
          title={t("E-Mail A Friend")}
          width={700}
          visible={emailFriendModal}
          style={{ top: 20 }}
          className="email_a_friend"
          maskClosable={false}
          onCancel={closeEmailFriendModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeEmailFriendModal}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              type="primary"
              onClick={handleSubmitEmailFriend}
            >
              {t("Send To Friend")}
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Name")} <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.name}
                        placeholder={t("Enter Your Name ")}
                        className={
                          emailFriendInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "name", "input")
                        }
                      />
                      {emailFriendInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your E-Mail")} <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.email}
                        placeholder={t("Enter Your E-Mail")}
                        className={
                          emailFriendInputDataValidation.emailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "email", "input")
                        }
                      />
                      {emailFriendInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Friend's Name")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.friendName}
                        placeholder={t("Enter Your Friend's Name")}
                        className={
                          emailFriendInputDataValidation.friendNameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "friendName", "input")
                        }
                      />
                      {emailFriendInputDataValidation.friendNameval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.friendNameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Friend's E-Mail")}{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.friendEmail}
                        placeholder={t("Enter Your Friend's E-Mail")}
                        className={
                          emailFriendInputDataValidation.friendEmailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "friendEmail", "input")
                        }
                      />
                      {emailFriendInputDataValidation.friendEmailval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.friendEmailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        {t("Add A Personal Message Here")} ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={emailFriendInputData.personalMessage}
                        placeholder={t("Add A Personal Message Here ...")}
                        className={
                          emailFriendInputDataValidation.personalMessageval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "personalMessage", "input")
                        }
                      ></textarea>
                      {emailFriendInputDataValidation.personalMessageval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.personalMessageval}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* E-Mail A Friend Modal Ends */}

        {/* Schedule Viewing Modal Starts */}
        <Modal
          title={t("Schedule A Viewing")}
          width={700}
          style={{ top: 20 }}
          visible={scheduleViewing}
          className="schedule_viewing"
          onCancel={closeScheduleViewingModal}
          maskClosable={false}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeScheduleViewingModal}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              onClick={handleSubmitSheduleViewing}
            >
              {t("Schedule")}
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>{t("See This Item & More In Our Store")}</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Name")} <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={scheduleViewingInputData.name}
                        placeholder={t("Enter Your Name ")}
                        className={
                          scheduleViewingInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "name", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Email")}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={scheduleViewingInputData.email}
                        className={
                          scheduleViewingInputDataValidation.emailVal &&
                          "border__red"
                        }
                        placeholder={t("Enter Your Email")}
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "email", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.emailVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Your Phone Number")}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={scheduleViewingInputData.phoneNumber}
                        className={
                          scheduleViewingInputDataValidation.phoneNumberVal &&
                          "border__red"
                        }
                        placeholder={t("Enter Your Phone Number")}
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "phoneNumber", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Location")}
                        <span className="mandatory">*</span>
                      </label>
                      <Select
                        name="location"
                        optionFilterProp="children"
                        value={scheduleViewingInputData.location}
                        className={
                          scheduleViewingInputDataValidation.locationVal
                            ? "border__red"
                            : "border__grey"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "location", "select")
                        }
                      >
                        <Option value="">{t("Select Location")}</Option>
                        {locationDetails &&
                          locationDetails.map((item, i) => {
                            return (
                              <Option value={item.LocationName}>
                                {item.LocationName}
                              </Option>
                            );
                          })}
                      </Select>
                      {scheduleViewingInputDataValidation.locationVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.locationVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("When are you available")}?
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        value={scheduleViewingInputData.appointmentDate}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentDate",
                            "input"
                          )
                        }
                        className={
                          scheduleViewingInputDataValidation.appointmentDateVal &&
                          "border__red"
                        }
                        min={disablePastDate()}
                      />
                      {scheduleViewingInputDataValidation.appointmentDateVal && (
                        <p className="error-color-red">
                          {
                            scheduleViewingInputDataValidation.appointmentDateVal
                          }
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Appointment Time")}
                        <span className="mandatory">*</span>
                      </label>
                      <Select
                        name="appointmentTime"
                        optionFilterProp="children"
                        className={
                          scheduleViewingInputDataValidation.appointmentTimeVal
                            ? "border__red"
                            : "border__grey"
                        }
                        value={scheduleViewingInputData.appointmentTime}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentTime",
                            "select"
                          )
                        }
                      >
                        <Option value="">{t("Appointment Time")}</Option>
                        <Option value="8:00 AM">{t("8 AM")}</Option>
                        <Option value="8:30 AM">{t("8:30 AM")}</Option>
                        <Option value="9:00 AM">{t("9 AM")}</Option>
                        <Option value="9:30 AM">{t("9:30 AM")}</Option>
                        <Option value="10:00 AM">{t("10 AM")}</Option>
                        <Option value="10:30 AM">{t("10:30 AM")}</Option>
                        <Option value="11:00 AM">{t("11 AM")}</Option>
                        <Option value="11:30 AM">{t("11:30 AM")}</Option>
                        <Option value="12:00 AM">{t("12 AM")}</Option>
                        <Option value="12:00 PM">{t("12:30 PM")}</Option>
                        <Option value="1:00 PM">{t("1 PM")}</Option>
                        <Option value="1:30 PM">{t("1:30 PM")}</Option>
                        <Option value="2:00 PM">{t("2 PM")}</Option>
                        <Option value="2:30 PM">{t("2:30 PM")}</Option>
                        <Option value="3:00 PM">{t("3 PM")}</Option>
                        <Option value="3:30 PM">{t("3:30 PM")}</Option>
                        <Option value="4:00 PM">{t("4 PM")}</Option>
                        <Option value="4:30 PM">{t("4:30 PM")}</Option>
                        <Option value="5:00 PM">{t("5 PM")}</Option>
                        <Option value="5:30 PM">{t("5:30 PM")}</Option>
                        <Option value="6:00 PM">{t("6 PM")}</Option>
                        <Option value="6:30 PM">{t("6:30 PM")}</Option>
                        <Option value="7:00 PM">{t("7 PM")}</Option>
                        <Option value="7:30 PM">{t("7:30 PM")}</Option>
                        <Option value="8:00 PM">{t("8 PM")}</Option>
                        <Option value="8:30 PM">{t("8:30 PM")}</Option>
                        <Option value="9:00 PM">{t("9 PM")}</Option>
                        <Option value="9:30 PM">{t("9:30 PM")}</Option>
                        <Option value="10:00 PM">{t("10 PM")}</Option>
                        <Option value="10:30 PM">{t("10:30 PM")}</Option>
                      </Select>
                      {scheduleViewingInputDataValidation.appointmentTimeVal && (
                        <p className="error-color-red">
                          {
                            scheduleViewingInputDataValidation.appointmentTimeVal
                          }
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        {t("Add A Personal Message Here")} ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={scheduleViewingInputData.message}
                        placeholder={t("Add A Personal Message Here ...")}
                        className={
                          scheduleViewingInputDataValidation.messageVal &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "message", "input")
                        }
                      ></textarea>
                      {scheduleViewingInputDataValidation.messageVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.messageVal}
                        </p>
                      )}
                    </div>
                  </div>
                  {adressHide ? (
                    <div className="col-md-12">
                      <p className="font__bold d-flex justify-between mb-0">
                        <span className="fw-bold mb-0">
                          {locationData.locationName
                            ? parse(locationData.locationName)
                            : ""}
                        </span>
                      </p>
                      <p className="font__bold mb-0">
                        <span className="fw-bold">
                          {locationData.phone ? locationData.phone : ""}
                        </span>
                      </p>
                      <p className="font__bold">
                        <a href={`mailto:${locationData.emailID}`}>
                          {locationData.emailID ? locationData.emailID : ""}
                        </a>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Schedule Viewing Modal Ends */}
        <Modal
          title={t("Certificate")}
          visible={viewCertModal}
          width={700}
          style={{ top: 20 }}
          className="cert_modal"
          onCancel={closeCertModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeCertModal}
            >
              {t("Cancel")}
            </Button>,
          ]}
        >
          <div className="row">
            <div>
              <Spin spinning={iframeLoading}>
                <iframe
                  onLoad={hideSpinner}
                  width="100%"
                  height="450px"
                  src={diamondInfoDetails.certlink}
                />
              </Spin>
            </div>
          </div>
        </Modal>

        <Modal
          title={t("Please confirm")}
          visible={confirmScheduleModal}
          width={700}
          style={{ top: 20 }}
          className="cert_modal"
          onCancel={() => setConfirmScheduleModal(false)}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={() => setConfirmScheduleModal(false)}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={handleSaveScheduleViewing}
              loading={buttonLoading}
            >
              {t("Schedule")}
            </Button>,
          ]}
        >
          <p>{t("Are you sure you want to schedule")}?</p>
        </Modal>

        <Modal
          title={t("Request Certificate")}
          width={700}
          visible={requestCertModal}
          style={{ top: 20 }}
          className="request_info"
          onCancel={closeCertInfoModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeCertInfoModal}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={requestCertModalLoading}
              type="primary"
              onClick={handleSubmitRequestCert}
            >
              {t("Request")}
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Name")} <span className="mandatory">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        value={certInfoData.name}
                        className={certInfoValidation.nameVal && "border__red"}
                        onChange={(e) =>
                          handleCertInfoChange(e, "name", "input")
                        }
                      />
                      {certInfoValidation.nameVal && (
                        <p className="error-color-red">
                          {certInfoValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("E-Mail Addres")}s{" "}
                        <span className="mandatory">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        value={certInfoData.email}
                        className={certInfoValidation.emailVal && "border__red"}
                        onChange={(e) =>
                          handleCertInfoChange(e, "email", "input")
                        }
                      />
                      {certInfoValidation.emailVal && (
                        <p className="error-color-red">
                          {certInfoValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        {t("Phone Number")} <span className="mandatory">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        value={certInfoData.phoneNumber}
                        className={
                          certInfoValidation.phoneNumberVal && "border__red"
                        }
                        onChange={(e) =>
                          handleCertInfoChange(e, "phoneNumber", "input")
                        }
                      />
                      {certInfoValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {certInfoValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>{t("Comments")}</label>
                      <textarea
                        value={certInfoData.comments}
                        onChange={(e) =>
                          handleCertInfoChange(e, "comments", "input")
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Thumbnail Slider Video Modal Starts */}
        <Modal
          title=""
          style={{ top: 20 }}
          visible={isModalVisible6}
          className="video__modal"
          onCancel={handleCancel6}
          footer={false}
        >
          <div className="video__section">
            <video
              width="100%"
              height="80%"
              src="https://up.diacam360.com/scan/90ad_1052947"
              autoPlay
              loop
              controls
            ></video>
          </div>
        </Modal>
        {/* Thumbnail Slider Video Modal Ends */}

        {/* For Internal Use Only Modal Starts */}

        <Modal
          title={t("For Internal Use Only")}
          width={700}
          style={{ top: 20 }}
          visible={internalUseModal}
          className="video__modal internal__use"
          onCancel={handleCancelInternalModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={handleCancelInternalModal}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              loading={internalUseModalLoading}
              type="primary"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={HandleCallForInternal}
            >
              {t("Submit")}
            </Button>,
          ]}
        >
          {showDealerInfo == true ? (
            <div className="form__fields border-0 mh-aut0">
              {dealerInfo &&
                dealerInfo.map((item, i) => {
                  return (
                    <div>
                      <p>
                        {t("Vendor Name")}:{" "}
                        {item.vendorName ? item.vendorName : "NA"}
                      </p>
                      <p>
                        {t("Vendor City/State")} :{" "}
                        {item.city || item.state
                          ? item.city + "/" + item.state
                          : "NA"}
                      </p>
                      <p>
                        {t("Vendor Phone Number")}:{" "}
                        {item.dealerPhone ? item.dealerPhone : "NA"}
                      </p>
                      <p>
                        {t("Vendor E-Mail")}:{" "}
                        {item.dealerEmail ? item.dealerEmail : "NA"}
                      </p>
                      <p>
                        {t("Vendor lot number of the item")}:{" "}
                        {item.dealerInventoryNo ? item.dealerInventoryNo : "NA"}
                      </p>
                      <p>
                        {t("WholeSale Price")}:{" "}
                        {item.dealerCost ? item.dealerCost : "NA"}
                      </p>
                      <p>
                        {" "}
                        {t("WholeSale Price")}: {}
                      </p>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="form__fields border-0">
              <div className="col-md-6">
                <div className="input__block">
                  <label htmlFor="">
                    {t("GF Password")} <span className="mandatory">*</span>
                  </label>
                  <input
                    type="password"
                    value={internalUseData.password}
                    className={internalUseDataval.passwordval && "border__red"}
                    onChange={(e) =>
                      handlepasswordMoreInfoChange(e, "password", "input")
                    }
                  />
                  {internalUseDataval.passwordval && (
                    <p className="error-color-red">
                      {internalUseDataval.passwordval}
                    </p>
                  )}
                </div>
                <span
                  className="reset__pass linkText"
                  onClick={() => {
                    setInternalUseData({
                      password: "",
                    });
                    window.open(`${document.referrer}?resetField=true`);
                  }}
                >
                  {t("Reset Password")}
                </span>
              </div>
            </div>
          )}
        </Modal>

        {/* For Internal Use Only Modal Ends */}

        {/* Modal Section Ends */}
      </React.Fragment>
    </React.Fragment>
  );
};

export default ViewDiamondDetails;
